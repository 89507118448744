<template>
  <div class="bidding__wrapper">
    <div class="commom_title">
      <a-row>
        <a-col :span="12">
          <a-row>
            <a-col :span="1">
              <div class="commom_line"></div>
            </a-col>
            <a-col :span="6">招投标信息</a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <div class="content_bidding">
      <a-row>
        <a-col :span="8">
          <div class="content_choose">
            <a-row>
              <a-col :span="6">
                <div class="content_text">地区</div>
              </a-col>
              <a-col :span="18">
                <a-row :gutter="10">
                  <a-col :span="12">
                    <a-select
                      v-model="provinceId"
                      style="width: 100%"
                      :options="provinceOption"
                      placeholder="请选择省份"
                      @change="handleGetArea"
                      allowClear
                    ></a-select>
                  </a-col>
                  <a-col :span="12">
                    <a-select
                      v-model="areaId"
                      style="width: 100%"
                      :options="areaOptions"
                      allowClear
                      placeholder="请选择城市"
                    ></a-select>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="content_choose">
            <a-row>
              <a-col :span="6">
                <div class="content_text">关键字搜索</div>
              </a-col>
              <a-col :span="18">
                <a-input-group compact style="display: flex">
                  <a-select v-model="searchType" style="width: 90px">
                    <a-select-option value="0"> 搜标题 </a-select-option>
                    <a-select-option value="1"> 搜全文 </a-select-option>
                  </a-select>
                  <a-input v-model="title" placeholder="请输入需要搜索的内容" allowClear> </a-input>
                </a-input-group>
                <!-- <a-input v-model="title" placeholder="请输入需要搜索的内容" allowClear>
                  <a-select slot="addonAfter" v-model="searchType" style="width: 90px">
                    <a-select-option value="0"> 搜标题 </a-select-option>
                    <a-select-option value="1"> 搜全文 </a-select-option>
                  </a-select>
                </a-input> -->
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="content_choose">
            <a-row>
              <a-col :span="6">
                <div class="content_text">时间选择</div>
              </a-col>
              <a-col :span="18">
                <div style="margin-top: 4px">
                  <a-radio-group name="radioGroup" v-model="day" >
                    <a-radio :value="1">近一月</a-radio>
                    <a-radio :value="2">近一周</a-radio>
                    <a-radio :value="3">今天</a-radio>
                  </a-radio-group>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="content_choose">
            <a-row>
              <a-col :span="6">
                <div class="content_text">招标类型</div>
              </a-col>
              <a-col :span="18">
                <a-select
                  v-model="type"
                  style="width: 100%"
                  :options="typeOption"
                  placeholder="请选择招标类型"
                ></a-select>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="content_choose">
            <a-row>
              <a-col :span="6">
                <div class="content_text">信息来源</div>
              </a-col>
              <a-col :span="18">
                <a-select
                  v-model="source"
                  style="width: 100%"
                  :options="sourceOption"
                  allowClear
                  placeholder="请选择信息来源"
                ></a-select>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="content_choose" style="text-align: right; padding-right: 30px">
            <a-button type="" @click="handleReset">重置</a-button>
            <a-button type="primary" @click="handleSearch">搜索</a-button>
          </div>
        </a-col>
      </a-row>
      <div class="content_list" style="height: 580px; padding: 0 24px">
        <v-card-list
          ref="cardList"
          :getDataApi="getDataApi"
          :renderItem="renderItem"
          :initPagination="{
            showLessItems: true,
            showQuickJumper: false,
          }"
          :colConfig="{
            span: 24,
          }"
        >
          <a-empty class="empty" slot="empty" />
          <template slot="pagination" slot-scope="page">
            <VPagination
              :total="page.total"
              :value="page.current"
              :pageSize="page.pageSize"
              @prev="$refs.cardList.handlePrev()"
              @next="$refs.cardList.handleNext()"
              @change="$refs.cardList.pageChange($event.page, $event.pageSize)"
            />
          </template>
        </v-card-list>
      </div>
    </div>
  </div>
</template>

<script>
import { districtQueryAllProvince, employmentEmployDistrict } from '@/api/workDemand'
import { getData } from '@/api/bidding/bidding'
import moment from 'moment'
import biddingProvince from '@/json/bidding-province.json'
import VPagination from '@/components/common/v-pagination'

export default {
  name: 'Index',
  components: {
    VPagination
  },
  data () {
    return {
      day: null,
      provinceId: undefined,
      provinceOption: [],
      areaId: undefined,
      type: '133',
      searchType: '1',
      source: '华能',
      areaOptions: [],
      title: '',
      typeOption: [
        {
          value: '135,134,133,155,156,176,177,178,179',
          label: '全部公告'
        },
        {
          value: '133',
          label: '招标公告'
        },
        {
          value: '134',
          label: '中标公告'
        },
        {
          value: '135',
          label: '招标变更'
        },
        {
          value: '155',
          label: '拟建项目'
        },
        {
          value: '156',
          label: '招标预告'
        },
        {
          value: '178',
          label: '资格预审'
        },
        {
          value: '179',
          label: '中标候选'
        }
      ],
      sourceOption: [
      {
          value: '华能',
          label: '华能'
        },
        {
          value: '华电',
          label: '华电'
        },
        {
          value: '国电',
          label: '国电'
        },
        {
          value: '国电投',
          label: '国电投'
        },
        {
          value: '国家能源',
          label: '国家能源'
        },
        {
          value: '大唐',
          label: '大唐'
        },
        {
          value: '华润集团',
          label: '华润集团'
        },
        {
          value: '中广核',
          label: '中广核'
        },
        {
          value: '中核',
          label: '中核'
        },
        {
          value: '东方电气',
          label: '东方电气'
        },
        {
          value: '三峡集团',
          label: '三峡集团'
        },
        {
          value: '中国电建',
          label: '中国电建'
        },
        {
          value: '能建',
          label: '能建'
        },
        {
          value: '国网',
          label: '国网'
        },
        {
          value: '南网',
          label: '南网'
        },
        {
          value: '蒙电',
          label: '蒙电'
        },
        {
          value: '铁塔',
          label: '铁塔'
        },
        {
          value: '中石化',
          label: '中石化'
        },
        {
          value: '中石油',
          label: '中石油'
        },
        {
          value: '中海油',
          label: '中海油'
        },
        {
          value: '中煤',
          label: '中煤'
        },
        {
          value: '一重集团',
          label: '一重集团'
        },
        {
          value: '地方采购',
          label: '地方采购'
        },
        {
          value: '电子卖场',
          label: '电子卖场'
        }
      ]
    }
  },
  created () {
    this.init()
  },
  methods: {
    Radiochange1 () {
      if (this.day == 1) {
        this.day = ''
      }
    },
    Radiochange2 () {
      if (this.day == 2) {
        this.day = ''
      }
    },
    Radiochange3 () {
      if (this.day == 3) {
        this.day = ''
      }
    },
    init () {
      this.provinceOption = biddingProvince.map((item) => ({
        value: item.id,
        label: item.district
      }))
    },
    handleGetArea (value) {
      this.$set(this, 'areaId', undefined)
      this.areaOptions = []
      if (value) {
        employmentEmployDistrict(value).then((res) => {
          this.areaOptions = res.map((item) => ({
            value: item.district.replace('市', ''),
            label: item.district.replace('市', '')
          }))
        })
      }
    },
    getDataApi (params) {
      const province = (this.provinceOption.find((item) => item.value === this.provinceId) || {}).label
      const area = (this.areaOptions.find((item) => item.value === this.areaId) || {}).label
      let day
      switch (this.day) {
        case 1:
          day = 'm1'
          break
        case 2:
          day = 'w'
          break
        case 3:
          day = 'd'
          break
      }
      return getData({
        region: province,
        area,
        fid: this.type,
        type: this.searchType,
        source: this.source,
        // industry_id: this.industryId,
        timeslot: day,
        keyword: this.title,
        limit: params.pageSize,
        page: params.pageIndex,
        tel: '15054273650'
      })
      // return Promise.resolve({
      //   data: biddingJson.slice(0, 10),
      //   pageIndex: 1,
      //   pageSize: 10,
      //   totalCount: 10
      // })
    },
    handleSearch () {
      this.$refs.cardList.pageInit()
    },
    handleReset () {
      this.provinceId = undefined
      this.areaId = undefined
      this.day = null
      this.source = undefined
      this.type = '135,134,133,155,156,176,177,178,179'
      this.title = ''
    },
    handleFormatTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },

    getDetail (id, time) {
      if (this.handleIsLogin()) {
        window.open(`/bidding/bidding-details?id=${id}&time=${time}`)
      }
    },
    renderItem ({ item }) {
      return (
        <div class="content_list_item" onClick={this.getDetail.bind(null, item.id, item.time)}>
          <a-row>
            <a-col span={20}>
              <div class="content_list_text" style="padding-left: 14px" domPropsInnerHTML={item.title}>
                {/* <div class="content_dian">·</div> */}
              </div>
            </a-col>
            <a-col span={2}>
              <div class="content_list_text">{item.region}</div>
            </a-col>
            <a-col span={2}>
              <div class="content_list_text">{item.time}</div>
            </a-col>
          </a-row>
        </div>
      )
    }
  }
}
</script>

<style lang="less">
@import url('../../common/style');

.bidding__wrapper {
  .content_list_text {
    padding-right: 10px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .content_dian {
    width: 6px;
    height: 6px;
    background: #1677ff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 7px;
    right: 8px;
  }
}
</style>
