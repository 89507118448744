<template>
  <div class="workDemand__wrapper">
    <div class="body_content">
      <div id="common_box">
        <div class="commom_title">
          <a-row type="flex">
            <a-col :span="16">
              <a-row>
                <a-col :span="1">
                  <div class="commom_line"></div>
                </a-col>
                <a-col :span="10">发布项目承揽</a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div class="content">
          <div class="right_content">
            <a-spin :spinning="rightLoading">
              <a-form-model
                ref="form"
                :model="form"
                :rules="rules"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 17 }"
              >
                <a-row class="form__title">
                  <a-col :span="12">
                    <a-form-model-item label="项目部" prop>
                      <a-select
                        :options="options"
                        allowClear
                        @change="handleGetProjectList"
                        v-model="form.projectDepartmentId"
                        placeholder="选择项目部"
                      ></a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-model-item label="项目名称" prop>
                      <a-select
                        :options="employmentEmploylist"
                        v-model="form.projectId"
                        allowClear
                        placeholder="选择项目名称"
                      ></a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <div class="content_item" v-for="(item, index) in form.demandInfoList" :key="index">
                  <a-row type="flex" class="item_title">
                    <a-icon
                      type="minus-circle"
                      :class="{
                        icon: true,
                        disabled: form.demandInfoList.length === 1,
                      }"
                      @click="handleDeleteForm(index)"
                    />
                    <a-col :span="24">
                      <a-form-model-item
                        :label-col="{ span: 3 }"
                        :wrapper-col="{ span: 21 }"
                        label="子项目名称"
                        :prop="'demandInfoList.' + index + '.wbProject'"
                        :rules="rules.wbProject"
                      >
                        <a-input v-model="item.wbProject" placeholder="锅炉焊口2000个" style="width: 300px" /><span
                          style="color: red"
                        >&nbsp;&nbsp;&nbsp;（注：子项目名称不可重复）</span
                        >
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row type="flex" class="item_title">
                    <a-col :span="12">
                      <a-form-model-item
                        label="报酬"
                        :prop="'demandInfoList.' + index + '.wbProjectSalary'"
                        :rules="rules.wbProjectSalary"
                      >
                        <a-input-number
                          style="width: 120px"
                          :min="1"
                          v-model="item.wbProjectSalary"
                          placeholder="8000"
                          :formatter="(value) => `${value}`"
                        />
                        元
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-model-item
                        label="项目地点"
                        :prop="'demandInfoList.' + index + '.place'"
                        :rules="rules.place"
                      >
                        <a-cascader
                          :options="areaOption"
                          v-model="item.place"
                          :fieldNames="{
                            label: 'district',
                            value: 'district',
                            children: 'children',
                          }"
                          placeholder="请选择项目地点"
                        />
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="12">
                      <a-form-model-item
                        label="子项目描述"
                        :prop="'demandInfoList.' + index + '.wbProjectDes'"
                        :rules="rules.wbProjectDes"
                      >
                        <a-textarea
                          :maxLength="200"
                          placeholder="为了您的需求能够被快速响应并能够得到高效的沟通，建议您在此区域描述清楚您的项目周期、工程质量验收要求等。"
                          v-model="item.wbProjectDes"
                          :rows="3"
                          style="width: 100%"
                        />
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <div class="content-label">
                    <div class="title">承揽该项目所需具备的条件</div>
                    <div class="tip">（可填写对人员的的条件要求，系统将根据条件为您智能匹配最合适的人员）</div>
                  </div>
                  <div class="item__content">
                    <a-row>
                      <a-col :span="12">
                        <a-form-model-item
                          label="工种"
                          :prop="'demandInfoList.' + index + '.positionId'"
                          :rules="rules.positionId"
                        >
                          <a-select
                            :options="dictPosition"
                            placeholder="请选择"
                            allowClear
                            @change="handleGetSkill(item.type, $event, index)"
                            v-model="item.positionId"
                            style="width: 200px"
                          ></a-select>
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-model-item
                          label="基本技能"
                          :prop="'demandInfoList.' + index + '.baseSkillIds'"
                          :rules="rules.baseSkillIds"
                        >
                          <a-checkbox-group v-model="item.baseSkillIds">
                            <a-checkbox v-for="items in baseSkillDtoList" :key="items.name" :value="items.id">{{
                              items.name
                            }}</a-checkbox>
                          </a-checkbox-group>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="24">
                        <a-form-model-item
                          label="专业技能"
                          :label-col="{ span: 3 }"
                          :wrapper-col="{ span: 21 }"
                          :prop="'demandInfoList.' + index + '.professionalSkillIds'"
                          :rules="
                            professionalSkillDtoList[index] && professionalSkillDtoList[index].length
                              ? { validator: validProfessionalSkillIds }
                              : null
                          "
                          :class="
                            professionalSkillDtoList[index] && professionalSkillDtoList[index].length ? 'required' : ''
                          "
                        >
                          <a-checkbox-group v-model="item.professionalSkillIds">
                            <a-checkbox
                              v-for="items in professionalSkillDtoList[index]"
                              :key="items.name"
                              :value="items.id"
                            >{{ items.name }}</a-checkbox
                            >
                          </a-checkbox-group>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :span="12">
                        <a-form-model-item
                          label="项目经历"
                          :prop="'demandInfoList.' + index + '.experience'"
                          :rules="rules.experience"
                        >
                          <a-radio-group name="radioGroup" v-model="item.experience">
                            <a-radio :value="0">不限</a-radio>
                            <a-radio :value="1">30万机组以下</a-radio>
                            <a-radio :value="2">30-60万机组</a-radio>
                            <a-radio :value="3">100万及以上机组</a-radio>
                          </a-radio-group>
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-model-item
                          label="技能等级证"
                          :prop="'demandInfoList.' + index + '.skillLevelCertificate'"
                          :rules="rules.skillLevelCertificate"
                        >
                          <a-radio-group name="radioGroup" v-model="item.skillLevelCertificate">
                            <a-radio :value="0">不限</a-radio>
                            <a-radio :value="1">初级</a-radio>
                            <a-radio :value="2">中级</a-radio>
                            <a-radio :value="3">高级</a-radio>
                            <a-radio :value="-1">特殊工种证书</a-radio>
                          </a-radio-group>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </a-form-model>
            </a-spin>

            <div class="form_add" @click="handleAddForm">
              <a-icon type="plus-circle" style="margin-right: 10px" />继续添加子项目
            </div>
            <div class="btn_box">
              <a-button class="btn__button" type="primary" :loading="pageLoading" @click="handleSubmit">发布</a-button>
            </div>
          </div>
        </div>
      </div>
      <a-modal
        centered
        v-model="isShow"
        :title="title"
        @ok="handleOk"
        okText="确认"
        cancelText="取消">
        <p class="modal_text" v-if="title == '发布成功'">
          您的物资需求已成功发布，可在“控制台-物资订单”选择合适的供应商进行确定合作意向。
        </p>
        <p class="modal_text" v-if="title == '保存成功'">
          您的用工需求模版已成功保存，可在“控制台-模版管理”进行编辑与查看。
        </p>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  employmentEmploybaseSkill,
  employmentEmployDictPosition,
  employmentEmployProject,
  employmentEmploypublish,
  enterprisePublishDemand,
  projectDepartment,
  getAreaTree
} from '@/api/workDemand'
import { Cascader } from 'ant-design-vue'

export default {
  name: 'Index',
  components: {
    'a-cascader': Cascader
  },
  data () {
    return {
      form: {
        demandInfoList: [{}]
      },
      rules: {
        wbProject: { required: true, message: '请填写子项目名称', trigger: 'blur' },
        wbProjectSalary: { required: true, message: '请填写报酬', trigger: 'blur' },
        type: { required: true, message: '请选择人员类别', trigger: 'blur' },
        positionId: { required: true, message: '请选择职位', trigger: 'blur' },
        baseSkillIds: { required: true, message: '请选择基本技能', trigger: 'blur' },
        experience: { required: true, message: '请选择项目经历', trigger: 'blur' },
        skillLevelCertificate: { required: true, message: '请选择技能等级证', trigger: 'blur' },
        place: { required: true, message: '请选择项目地点', trigger: 'blur' },
        wbProjectDes: { required: true, message: '请输入子项目描述', trigger: 'blur' }
      },
      requirements: [
        { name: '安全员', id: '1' },
        { name: '建造师', id: '2' }
      ],
      areaOption: [],
      isSave: false,
      title: '',
      isShow: false,
      visible: false,
      options: [],
      employmentEmploylist: [],
      personnelType: [],
      dictPosition: [],
      baseSkillDtoList: [],
      professionalSkillDtoList: [],
      obj: {},
      name: '',
      Provincelist: [],
      modleList: [],
      District: [],
      projectId: '',
      projectDepartmentId: '',
      list: [
        {
          province: '',
          ids: 0,
          type: '',
          city: '',
          // 招聘职位
          positionId: '',
          // 基本技能
          baseSkillIds: [],
          // 项目经历
          experience: '',
          //  子项目描述
          wbProjectDes: '',
          //  项目地点
          place: '',
          // 专业技能id
          professionalSkillIds: [],
          // 项目结束时间
          projectEndTime: '',
          // 项目开始时间
          projectStartTime: '',
          // 从业资格证id集合
          qualificationCertificateIds: [],
          // 技能等级证;0
          skillLevelCertificate: ''
        }
      ],
      id: '',
      templateType: 'system',
      templateName: '',
      templateData: [],
      leftLoading: false,
      rightLoading: false,
      temId: '',
      loading: false,
      pageLoading: false
    }
  },
  mounted () {
    this.$init()
  },
  methods: {
    $init () {
      this.handleGetBasicData()
    },
    handleGetBasicData () {
      projectDepartment() // 项目部
        .then((res) => {
          this.options = res.data.map((item) => ({
            ...item,
            label: item.projectDepartment,
            value: item.projectDepartmentId
          }))
        })
      getAreaTree() // 城市
        .then((res) => {
          this.areaOption = res
        })
      // 工种
      employmentEmployDictPosition({ type: 1 }).then((res) => {
        const data = res.data.map((item) => ({
          ...item,
          label: item.majorName,
          value: item.id.toString()
        }))
        this.dictPosition = data
      })
      // 基本技能
      employmentEmploybaseSkill({ type: 1 }).then((res) => {
        if (res.data) {
          this.baseSkillDtoList = res.data.baseSkillDtoList
        }
      })
    },
    handleGetSkill (type = 1, positionId, index) {
      this.$set(this.professionalSkillDtoList, index, [])
      this.$set(this.form.demandInfoList[index], 'professionalSkillIds', [])
      // 技能
      employmentEmploybaseSkill({
        type,
        positionId
      }).then((res) => {
        if (res.data) {
          if (positionId) {
            this.$set(this.professionalSkillDtoList, index, res.data.professionalSkillDtoList || [])
            if (!res.data.professionalSkillDtoList.length) {
              this.$refs.form.clearValidate(`demandInfoList.${index}.professionalSkillIds`)
            }
          } else {
            this.professionalSkillDtoList[index] = []
          }
        }
      })
    },
    handleDeleteForm (index) {
      if (this.form.demandInfoList.length === 1) {
        return
      }
      this.form.demandInfoList.splice(index, 1)
      this.form.dictPosition.splice(index, 1)
      this.form.baseSkillDtoList.splice(index, 1)
      this.form.professionalSkillDtoList.splice(index, 1)
    },
    handleGetProjectList (projectDepartmentId) {
      // 项目
      this.$set(this.form, 'projectId', undefined)
      this.employmentEmploylist = []
      if (projectDepartmentId) {
        employmentEmployProject({ projectDepartmentId }).then((res) => {
          this.employmentEmploylist = res.data.map((item) => ({
            ...item,
            label: item.projectName,
            value: item.projectId
          }))
        })
      }
    },
    validProfessionalSkillIds (rule, value, callback) {
      if (this.form.demandInfoList.type === '2') {
        callback()
      } else {
        if (!value || !value.length) {
          callback(new Error('请选择专业技能'))
        }
        callback()
      }
    },
    handleAddForm () {
      const length = this.form.demandInfoList.length
      this.$set(this.form.demandInfoList, length, {})
    },
    handleOk () {
      this.isSave = false
      this.isShow = false
    },
    handleFormatModel () {
      return {
        ...this.form,
        demandInfoList: this.form.demandInfoList.map((item) => ({
          ...item,
          flag: 2,
          place: item.place.join('-')
        }))
      }
    },
    handleSubmit () {
      if (!this.form.projectId) {
        this.$message.error('请选择项目名称')
      } else {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.rightLoading = true
            this.pageLoading = true
            const model = this.handleFormatModel()
            employmentEmploypublish(model)
              .then(() => {
                this.$message.success('职位发布成功')
                this.$set(this, 'form', { demandInfoList: [{}] })
                this.temId && this.handleClickItem({ templateId: this.temId })
              })
              .finally(() => {
                this.rightLoading = false
                this.pageLoading = false
              })
          }
        })
      }
    }
  }
}
</script>

<style lang="less">
@import url('../../common/style');
.workDemand__wrapper {
  .required {
    .ant-form-item-label {
      label {
        position: relative;
        &::before {
          display: inline-block;
          margin-right: 4px;
          color: #f5222d;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }
      }
    }
  }
  .content {
    display: flex;
    .left_content {
      width: 260px;
      margin-right: 30px;
      .left_content_header {
        display: flex;
        justify-content: space-between;
      }
      .left_content_body {
        margin-top: 10px;
        height: calc(100% - 150px);
        overflow: auto;
        .ant-list-item {
          background: #ffffff;
          border: none;
          padding-left: 26px;
          cursor: pointer;
          &:hover {
            background: #f0f0f0;
            color: #1677ff;
          }
          &.active {
            background: #f0f0f0;
            color: #1677ff;
          }
        }
      }
    }
    .right_content {
      flex: 1;
      .content_item {
        margin-top: 24px;
      }
      .form__title {
        padding: 24px 0 0 0;
        background: #f5f5f5;
        opacity: 1;
        margin-bottom: 24px;
      }
      .item_title {
        margin-bottom: 0px;
        .ant-form-item-label {
          label {
            font-size: 18px;
            font-weight: 500;
            color: #000000;
          }
        }
      }
      .icon {
        font-size: 18px;
        margin-top: 10px;
        margin-right: 10px;
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
  .template_btn {
    width: 110px;
    height: 36px;
    background: #dedede;
    margin: 20px 0;
    cursor: pointer;
    text-align: center;
    line-height: 36px;
    color: #fff;
    &.active {
      background: #1677ff;
    }
  }
  .item__content {
    border: 1px dashed #707070;
  }
  .form_add {
    width: 160px;
    margin: 20px auto;
    color: #1677ff;
    font-size: 18px;
    cursor: pointer;
  }
  .btn_box {
    display: flex;
    justify-content: flex-end;
    .btn__button {
      width: 150px;
      text-align: center;
      margin-left: 10px;
    }
  }
  .content-label {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    .title {
      height: 25px;
      font-size: 18px;
      font-weight: 550;
      color: #000000;
      margin-right: 12px;
    }
    .tip {
      font-size: 12px;
      font-weight: 400;
      color: #909399;
    }
  }
}
</style>
