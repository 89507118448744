<template>
  <div class="user-balance-wrapper">
    <div class="user-box">
      <div class="user-detail">
        <div class="box-title">储值中心</div>
        <a-avatar class="avatar" :size="142" icon="user" />
        <div class="flex user-name-box">
          <div
            class="user-name"
          >{{ userInfo.enterpriseInfoDto && userInfo.enterpriseInfoDto.enterpriseName }}</div>
          <div
            class="copy"
            @click="handleCopy(userInfo.enterpriseInfoDto && userInfo.enterpriseInfoDto.enterpriseName)"
          >复制</div>
        </div>
        <div class="flex user-id-box">
          <div class="user-id">账号ID：{{ userInfo.username }}</div>
          <div class="copy" @click="handleCopy(userInfo.username)">复制</div>
        </div>
      </div>
      <div class="balance-box flex">
        <div class="left">
          <div class="balance-icon"></div>
        </div>
        <div class="right">
          <div class="balance-title">账户余额：</div>
          <div class="balance-money-box flex">
            <div class="balance-money">¥ {{ enterpriseMoney }}</div>
            <div
              class="balance-btn"
              @click="isRecharge = !isRecharge"
            >{{ isRecharge ? '收支明细' : '余额充值' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-box">
      <recharge v-if="isRecharge" />
      <rechargeDetail v-else />
    </div>
  </div>
</template>

<script>
import { handleCopy } from '@/utils/util'
import defaultPng from '@/assets/home/default.png'
import recharge from './recharge.vue'
import rechargeDetail from './recharge-detail.vue'
import { queryBalance } from '@/api/balance'

export default {
  name: 'UserBalance',
  components: {
    recharge,
    rechargeDetail
  },
  computed: {
    userInfo () {
      return this.$store.getters.userInfo || {}
    }
  },
  data () {
    return {
      handleCopy,
      defaultPng,
      isRecharge: true,
      enterpriseMoney: '0.00'
    }
  },
  created () {
    this.isRecharge = this.$route.query.isRecharge === 'true'
    queryBalance({
      enterpriseId: this.$store.getters.userInfo?.enterpriseInfoDto?.enterpriseId
    }).then(res => {
      this.enterpriseMoney = res?.data?.money || '0.00'
    })
  }
}
</script>

<style lang="less">
.user-balance-wrapper {
  display: flex;
  padding: 25px 48px;
  height: 857px;
  background: #f6f6f6;
  .flex {
    display: flex;
  }
  .user-box {
    width: 336px;
    margin-right: 25px;

    .user-detail {
      width: 100%;
      height: 336px;
      background: url('../../assets/balance/bg.png') no-repeat;
      background-size: 100% 100%;
      .box-title {
        width: 133px;
        height: 32px;
        margin: 0 auto;
        text-align: center;
        background: url('../../assets/balance/rect.png') no-repeat;
        background-size: 100% 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        color: #ffffff;
      }
      .avatar {
        margin: 32px auto;
        display: block;
      }
    }
    .user-name-box,
    .user-id-box {
      width: 80%;
      margin: 0 auto;
      justify-content: space-between;
    }
    .user-name-box {
      margin-bottom: 15px;
      .user-name {
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #333333;
      }
    }
    .user-id {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
    .copy {
      width: 60px;
      height: 22px;
      border: 1px solid #1677ff;
      border-radius: 2px;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      color: #1677ff;
    }

    .balance-box {
      height: 89px;
      padding: 5px 12px;
      background: #ffffff;
      margin-top: 5px;
      border-radius: 4px;

      .left {
        width: 82px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .balance-icon {
        background: url('../../assets/balance/qb.png') no-repeat;
        background-size: 100% 100%;
        width: 51px;
        height: 45px;
      }
      .right {
        background: #f6f6f6;
        flex: 1;
        padding: 18px 19px;
        .balance-title {
          font-weight: 500;
          font-size: 12px;
          color: #666666;
        }
        .balance-money-box {
          justify-content: space-between;
          align-items: center;
        }
        .balance-money {
          font-weight: 500;
          font-size: 18px;
          color: #666666;
        }
        .balance-btn {
          font-weight: 400;
          font-size: 11px;
          text-decoration-line: underline;
          color: #1677ff;
          cursor: pointer;
        }
      }
    }
  }
  .table-box {
    flex: 1;
    background: #ffffff;
  }
}
</style>
