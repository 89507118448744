<template>
  <div class="balance-recharge-wrapper">
    <div class="com-header">充值</div>
    <div class="com-body">
      <div class="tips-box">
        <div class="tip-title">温馨提示：</div>
        <div class="tip-detail">
          本平台目前仅支持线下对公账户汇款形式，请您如实填写汇款相关凭据信息，受银行处理时间影响，线下汇款方式到账会有延误，我们将在确认收款后2个工作日内为您充值到账，如遇充值未及时到账问题，可致电400-625-9698，我们将竭诚为您解决问题。
        </div>
        <div class="tips-content">
          <div class="title">本平台唯一指定付款账户：</div>
          <div class="company-box">
            <div class="label-content">
              <div class="info-label">对公银行账户号：</div>
              <div class="info-label">账户主体：</div>
              <div class="info-label">开户行信息：</div>
              <div class="info-label">电话：</div>
            </div>
            <div class="value-content">
              <div class="info-value">5329 1314 4010 366</div>
              <div class="info-value">鼎基智能云平台有限公司</div>
              <div class="info-value">招商银行青岛国际创新园支行</div>
              <div class="info-value">0532-55786657</div>
            </div>
          </div>
          <div class="copy-btn" @click="handleCopy">一键复制</div>
        </div>
      </div>
      <div class="recharge-box">
        <div class="left-form-box">
          <a-form-model ref="form" labelAlign="left" :model="form" v-bind="formItemLayout" :rules="rules">
            <a-form-model-item label="充值金额：" prop="money">
              <a-input v-model="form.money" placeholder="请输入充值金额（元）例：0.11" />
            </a-form-model-item>
            <a-form-model-item label="充值凭据：" prop="voucher">
              <a-upload
                name="file"
                list-type="picture-card"
                :file-list="fileList"
                @preview="handlePreview"
                style="width: 50%"
                :action="action"
                :headers="headers"
                @change="handleFileListChange"
                :remove="handleFileListRemove"
                class="avatar-uploader"
                accept=".png, .jpg, jpeg"
              >
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">凭据上传</div>
                </div>
              </a-upload>
              <span class="img-view" @click="handleView">图片示例</span>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="formItemLayout.wrapperCol">
              <a-button type="primary" class="sub-btn" @click="handleSubmit" :loading="btnLoading">立即提交</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="right-table-box">
          <div class="table-title">充值记录</div>
          <div class="table-search">
            <div class="search-item">
              <span class="search-item-label">创建时间：</span>
              <a-range-picker
                v-model="searchForm.createTime"
                value-format="YYYY-MM-DD"
                @change="$refs.table.initPage()"
              />
            </div>
            <div class="search-item">
              <span class="search-item-label">更新时间：</span>
              <a-range-picker
                v-model="searchForm.updateTime"
                value-format="YYYY-MM-DD"
                @change="$refs.table.initPage()"
              />
            </div>
          </div>
          <div class="table-content">
            <p-table row-key="id" :columns="tableColumn" :source-data="getTableData" ref="table" :scroll="{ y: 240 }">
              <template slot="createTime" slot-scope="text">{{ formatTime(text) }}</template>
              <template slot="status" slot-scope="text">
                <a-tag :color="text == 0 ? 'rgba(252, 154, 8, 1)' : 'rgba(1, 190, 77, 1)'">{{
                  text == 0 ? '未到账' : '已到账'
                }}</a-tag>
              </template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import billView from '@/assets/balance/shili.png'
import { handleCopy } from '@/utils/util'
import VueCookeis from 'vue-cookies'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { postRecharge, queryBalanceDetails } from '@/api/balance'

export default {
  name: 'Recharge',
  data () {
    return {
      action: `${process.env.VUE_APP_API_BASE_URL}/api/enterprise/employmentFile/EnterpriseFileController/uploadImage`,
      headers: {
        token: VueCookeis.get(ACCESS_TOKEN),
        clientId: process.env.VUE_APP_CLIENT_ID
      },
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      form: {
        voucher: ''
      },
      searchForm: {},
      fileList: [],
      rules: {
        money: [
          { required: true, message: '请输入充值金额' },
          { pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.{1}\d{1,2})|([1-9]+\d*))$/, message: '请输入正确充值金额' }
        ],
        voucher: [{ required: true, message: '请选择上传充值凭据' }]
      },
      btnLoading: false,
      tableColumn: [
        {
          title: '序号',
          dataIndex: 'key',
          customRender: (text, row, index) => index + 1,
          width: 50
        },
        {
          title: '业务流水号',
          dataIndex: 'number',
          width: 265
        },
        {
          title: '充值金额',
          dataIndex: 'money'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 100
        }
      ]
    }
  },
  methods: {
    handlePreview (file) {
      this.$hevueImgPreview(file.response.path)
    },
    handleFileListChange ({ file, fileList }) {
      this.fileList = fileList
      if (file.status === 'done') {
        this.form.voucher = file.response.path
      }
    },
    handleFileListRemove (file) {
      this.fileList = []
      this.form.voucher = ''
      return true
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          postRecharge({
            ...this.form,
            enterpriseId: this.$store.getters.userInfo?.enterpriseInfoDto?.enterpriseId
          })
            .then(() => {
              this.$message.success('账户余额充值申请提交成功')
              this.form = {
                voucher: ''
              }
              this.fileList = []
              this.$refs.table.initPage()
            })
            .finally(() => {
              this.btnLoading = false
            })
        }
      })
    },
    getTableData (params) {
      const { createTime = [], updateTime = [] } = this.searchForm
      return queryBalanceDetails({
        ...params,
        createTime: createTime.join(','),
        updateTime: updateTime.join(','),
        enterpriseId: this.$store.getters.userInfo?.enterpriseInfoDto?.enterpriseId,
        type: 1
      })
    },
    handleView () {
      this.$hevueImgPreview(billView)
    },
    handleCopy () {
      const text = `对公银行账户号：5329 1314 4010 366, 账户主体：鼎基智能云平台有限公司, 开户行信息：招商银行青岛国际创新园支行, 电话：0532-55786657`
      handleCopy(text)
    }
  }
}
</script>

<style lang="less">
.balance-recharge-wrapper {
  .com-header {
    height: 53px;
    padding: 0 24px;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    line-height: 53px;
    border-bottom: 1px solid #b1b1b1;
  }
  .com-body {
    padding: 24px;
    .tips-box {
      height: 242px;
      background: #f9f9f9;
      border: 1px solid #d9d9d9;
      padding: 12px 24px;
      color: #000000;
      .tip-title {
        font-weight: 550;
        font-size: 16px;
      }
      .tip-detail {
        margin: 10px 0px;
        text-indent: 24px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .tips-content {
        display: flex;
        .title {
          text-indent: 24px;
        }
        .company-box {
          padding: 9px 25px;
          background: #cbdffa;
          display: flex;
          .info-label {
            width: 122px;
            height: 24px;
            background: #1677ff;
            color: #ffffff;
            line-height: 24px;
            text-align: right;
            margin-bottom: 1px;
          }
          .info-value {
            width: 340px;
            height: 24px;
            text-indent: 10px;
            margin-bottom: 1px;
            background: #70abfd;
            line-height: 24px;
            color: #ffffff;
          }
        }
        .copy-btn {
          font-weight: 400;
          font-size: 12px;
          text-decoration-line: underline;
          color: #1677ff;
          cursor: pointer;
          margin: 100px 0 0 14px;
        }
      }
    }
    .recharge-box {
      margin-top: 24px;
      display: flex;
      .left-form-box {
        width: 30%;
        min-width: 300px;
        height: 440px;
        border-right: 1px solid #b1b1b1;
        margin-right: 24px;

        .sub-btn {
          width: 100%;
          height: 48px;
          background: #1677ff;
          margin-top: 40px;

          .ant-form-item-children {
            display: flex;
            align-items: center;
            justify-items: center;
          }
        }
        .img-view {
          display: inline-block;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          text-decoration-line: underline;
          color: #1677ff;
          position: relative;
          top: -10px;
          cursor: pointer;
        }
      }
      .right-table-box {
        flex: 1;
        .table-title {
          font-weight: 600;
          font-size: 18px;
          color: #333333;
        }
        .table-search {
          padding: 20px 0;
          display: flex;
          align-items: center;
          .search-item {
            display: flex;
            align-items: center;
          }
          .search-item:first-child {
            margin-right: 40px;
          }
          .search-item-label {
            display: inline-block;
            min-width: 70px;
          }
          .ant-calendar-picker {
            max-width: 294px;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
