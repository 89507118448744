<template>
  <div class="input__content_wrapper" @click="handleClick">
    <div class="opticty__input__content">
      <a-input
        class="opticty__input"
        ref="input"
        v-model="targetValue"
        @input="handleInput"
        @blur="isFocus = false"
        @focus="isFocus = true"
        :allowClear="false"
      ></a-input>
    </div>
    <div class="render__wrapper">
      <div v-for="(data,index) in valueArr" :key="index" class="input__item">
        <div
          :class="{
            'v__input': true,
            'v__input__actived': index === value.length && isFocus
        }"
        >{{data}}</div>
      </div>
    </div>
  </div>
</template>

<script>
function getValue(value = '') {
  const arr = []
  for (let i = 0; i < 6; i++) {
    arr.push(value.charAt(i))
  }
  return arr
}

export default {
  name: 'VInput',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.targetValue = val
      }
    }
  },
  computed: {
    valueArr() {
      return getValue(this.value)
    }
  },
  data() {
    return {
      targetValue: this.value,
      isFocus: false
    }
  },
  methods: {
    handleInput(value) {
      const newValue = typeof value === 'string' ? value : value.target.value
      const numValue = newValue
        .replace(/[^0-9A-Za-z]+/g, '')
        .substring(0, 6)
        .toUpperCase()
      this.targetValue = numValue
      this.$emit('input', numValue)
    },
    handleClick() {
      this.isFocus = true
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="less">
.input__content_wrapper {
  width: 100%;
  display: flex;
  position: relative;
  .opticty__input__content {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .render__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .input__item {
    height: 40px;
    width: 40px;
    pointer-events: none;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #333333;
    font-weight: 550;
  }
  .v__input {
    height: 40px;
    width: 40px;
    border: 1px solid #eaeaea;
  }
  .v__input__actived {
    &::after {
      content: '';
      display: inline-block;
      width: 1px;
      border-radius: 4px;
      height: 18px;
      top: 12px;
      position: absolute;
      animation: blink 1s infinite steps(1, start);
      -webkit-animation: blink 1s infinite steps(1, start);
      -moz-animation: blink 1s infinite steps(1, start);
    }
  }
  @keyframes blink {
    100% {
      background-color: #ffffff;
      display: inline-block;
    }
    50% {
      background-color: #000; /* not #aaa because it's seem there is Google Chrome bug */
      display: inline-block;
    }
  }
}
</style>