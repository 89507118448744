<template>
  <div class="bidding-enter__wrapper">
    <div class="commom_title">
      <a-row>
        <a-col :span="12">
          <a-row>
            <a-col :span="1">
              <div class="commom_line"></div>
            </a-col>
            <a-col :span="6">站内招投标信息</a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <div class="content_bidding">
      <a-row>
        <a-col :span="8">
          <div class="content_choose">
            <a-row>
              <a-col :span="6">
                <div class="content_text">项目地点</div>
              </a-col>
              <a-col :span="18">
                <a-row :gutter="10">
                  <a-col :span="12">
                    <a-select
                      v-model="provinceId"
                      style="width: 100%"
                      :options="provinceOption"
                      placeholder="请选择省份"
                      @change="handleGetArea"
                      allowClear
                    ></a-select>
                  </a-col>
                  <a-col :span="12">
                    <a-select
                      v-model="areaId"
                      style="width: 100%"
                      :options="areaOptions"
                      allowClear
                      placeholder="请选择城市"
                    ></a-select>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="content_choose">
            <a-row>
              <a-col :span="6">
                <div class="content_text">关键字搜索</div>
              </a-col>
              <a-col :span="18">
                <a-input v-model="title" placeholder="请输入需要搜索的内容" allowClear />
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="content_choose">
            <a-row>
              <a-col :span="6">
                <div class="content_text">时间选择</div>
              </a-col>
              <a-col :span="18">
                <div style="margin-top: 4px">
                  <a-radio-group name="radioGroup" v-model="day" @change="handleChange">
                    <a-radio :value="1">近一月</a-radio>
                    <a-radio :value="2">近一周</a-radio>
                    <a-radio :value="3">今天</a-radio>
                  </a-radio-group>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="content_choose">
            <a-row>
              <a-col :span="6">
                <div class="content_text">项目类型</div>
              </a-col>
              <a-col :span="18">
                <a-select
                  v-model="type"
                  allowClear
                  style="width: 100%"
                  :options="typeList"
                  placeholder="请选择项目类型"
                ></a-select>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="content_choose">
            <a-row>
              <a-col :span="6">
                <div class="content_text">发布企业</div>
              </a-col>
              <a-col :span="18">
                <a-input v-model="publishEnterprise" placeholder="请输入需要搜索的发布企业" allowClear />
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="content_choose" style="text-align: right; padding-right: 30px">
            <a-button type="" @click="handleReset">重置</a-button>
            <a-button type="primary" @click="handleSearch">搜索</a-button>
          </div>
        </a-col>
      </a-row>
      <div class="card-list-header">
        <div class="card-list-header-title">公告标题</div>
        <div class="card-list-header-status">公告状态</div>
        <div class="card-list-header-region">项目地点</div>
        <div class="card-list-header-time">中标公告</div>
        <div class="card-list-header-time">发布时间</div>
      </div>
      <div class="content_list" style="height: 580px; padding: 0 24px">
        <v-card-list
          ref="cardList"
          :getDataApi="getDataApi"
          :renderItem="renderItem"
          :colConfig="{
            span: 24,
          }"
        >
          <a-empty class="empty" slot="empty" />
        </v-card-list>
      </div>
    </div>
  </div>
</template>

<script>
import { districtQueryAllProvince, employmentEmployDistrict } from '@/api/workDemand'
import { getData } from '@/api/bidding/bidding'
import { queryPageByConditionGET } from '@/api/TenderingBidding'
import moment from 'moment'
import biddingProvince from '@/json/bidding-province.json'
import { zbPubStatus, typeList } from '@/views/TenderingBidding/dict'

export default {
  name: 'Index',
  data () {
    return {
      day: null,
      provinceId: undefined,
      provinceOption: [],
      areaId: undefined,
      type: undefined,
      publishEnterprise: undefined,
      areaOptions: [],
      title: '',
      typeList,
      zbPubStatus
    }
  },
  created () {
    this.init()
  },
  methods: {
    handleChange (e, value) {
      console.log(e, value)
    },
    Radiochange1 () {
      if (this.day == 1) {
        this.day = ''
      }
    },
    Radiochange2 () {
      if (this.day == 2) {
        this.day = ''
      }
    },
    Radiochange3 () {
      if (this.day == 3) {
        this.day = ''
      }
    },
    init () {
      this.provinceOption = biddingProvince.map((item) => ({
        value: item.id,
        label: item.district
      }))
    },
    handleGetArea (value) {
      this.$set(this, 'areaId', undefined)
      this.areaOptions = []
      if (value) {
        employmentEmployDistrict(value).then((res) => {
          this.areaOptions = res.map((item) => ({
            value: item.district,
            label: item.district
          }))
        })
      }
    },
    getDataApi (params) {
      const province = (this.provinceOption.find((item) => item.value === this.provinceId) || {}).label || ''
      const area = (this.areaOptions.find((item) => item.value === this.areaId) || {}).label || ''
      let startTime, endTime
      endTime = moment().add(1, 'd').format('YYYY-MM-DD')
      switch (this.day) {
        case 1:
          startTime = moment().subtract(1, 'M').format('YYYY-MM-DD')
          break
        case 2:
          startTime = moment().subtract(1, 'w').format('YYYY-MM-DD')
          break
        case 3:
          startTime = moment().format('YYYY-MM-DD')
          break
      }
      console.log(startTime, endTime)
      return queryPageByConditionGET({
        place: area ? province + ',' + area : province,
        publishEnterprise: this.publishEnterprise,
        type: this.type,
        publishStatus: '2,3',
        title: this.title,
        createTime: startTime ? [startTime, endTime].join(',') : ''
      })
      // return Promise.resolve({
      //   data: biddingJson.slice(0, 10),
      //   pageIndex: 1,
      //   pageSize: 10,
      //   totalCount: 10
      // })
    },
    handleSearch () {
      this.$refs.cardList.pageInit()
    },
    handleReset () {
      this.provinceId = undefined
      this.areaId = undefined
      this.day = null
      this.type = undefined
      this.publishEnterprise = undefined
      this.title = ''
    },
    handleFormatTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },

    getDetail (id, time, e) {
      if (e.target.className === 'item-look') return
      if (this.handleIsLogin()) {
        window.open(`/bidding/bidding-details-enter?id=${id}`)
      }
    },
    getClassType ({ type }) {
      const index = this.typeList.findIndex((item) => item.value == type)
      return {
        'item-type': true,
        [`item-type-${index}`]: true
      }
    },
    getClassStatus ({ publishStatus }) {
      const index = this.zbPubStatus.findIndex((item) => item.value == publishStatus)
      return {
        'item-status': true,
        [`item-status-${index}`]: true
      }
    },
    handleClick (item, e) {
      if (this.handleIsLogin()) {
        window.open(`/bidding/bidding-details-enter?id=${item.id}&dataType=2`)
      }
    },
    renderItem ({ item }) {
      return (
        <div class="bidding-data-item" onClick={this.getDetail.bind(null, item.id, item.time)}>
          <div class={this.getClassType(item)}>{item.type}</div>
          <div class="item-title">{item.title}</div>
          <div class={this.getClassStatus(item)}>
            {this.zbPubStatus.find((data) => data.value == item.publishStatus).label}
          </div>
          <div class="item-region">{item.place.split(',')[1]}</div>
          {item.publishStatus == '3' ? (
            <div class="item-look" style="color: #1890ff;cursor: pointer" onClick={this.handleClick.bind(this, item)}>
              查看
            </div>
          ) : (
            <div class="item-look">未发布</div>
          )}
          <div class="item-time">{this.handleFormatTime(item.createTime)}</div>
        </div>
      )
    }
  }
}
</script>

<style lang="less">
@import url('../../common/style');

.bidding-enter__wrapper {
  .bidding-data-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 51px;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
    .item-type {
      width: 48px;
      height: 20px;
      border-radius: 17px;
      margin-right: 10px;
      text-align: center;
      font-size: 12px;
      &-0 {
        background: #ffabab;
        color: #ff3d3d;
      }
      &-1 {
        background: #d4ffd3;
        color: #17b114;
      }
      &-2 {
        background: #ffddab;
        color: #ff833d;
      }
      &-3 {
        background: #abf0ff;
        color: #27da99;
      }
      &-4 {
        background: #dbbfff;
        color: #6027da;
      }
    }
    .item-title {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .item-status {
      width: 80px;
      text-align: center;
      &-0 {
        color: #1677ff;
      }
      &-1 {
        color: #fc9a08;
      }
    }
    .item-region {
      width: 80px;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
      overflow: hidden;
    }
    .item-time {
      width: 80px;
      text-align: center;
      text-align: right;
    }
    .item-look {
      width: 80px;
      text-align: center;
      text-align: center;
    }
  }
  .card-list-header {
    margin-top: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 550;
    padding: 0 20px;
    &-title {
      flex: 1;
    }
    &-status {
      width: 80px;
      text-align: center;
    }
    &-region {
      width: 80px;
      text-align: center;
    }
    &-btn {
      width: 80px;
      text-align: center;
    }
    &-time {
      width: 80px;
      text-align: center;
    }
  }
  .content_list{
    margin-top: 0;
  }
}
</style>
